<template>
  <div class="wrapper">
    <el-form :inline="true" :model="query" class="search" size="small">
      <el-form-item label="名称:">
        <el-input v-model="query.title" clearable placeholder="请输入评价表名称"></el-input>
      </el-form-item>
      <el-form-item label="学年:">
        <el-select v-model="query.year" clearable placeholder="请选择学年">
          <el-option
            v-for="item in yearOpt"
            :key="item.key"
            :label="item.value"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学期:">
        <el-select v-model="query.semester" clearable placeholder="请选择学期">
          <el-option
            v-for="item in semesterOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchHandle">查询</el-button>
        <el-button type="primary" plain icon="el-icon-back" @click="backHandle"  >返回</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }"
      border
      style="width: 100%">
      <el-table-column
        type="index"
        width="60"
        align="center"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="paperName"
        align="center"
        label="评价表">
      </el-table-column>
      <el-table-column
        prop="personScore"
        width="100"
        align="center"
        label="个人得分">
      </el-table-column>
      <el-table-column
        prop="classAvgScore"
        width="100"
        align="center"
        label="班级均分">
      </el-table-column>
      <el-table-column
        prop="fullScore"
        width="100"
        align="center"
        label="满分">
      </el-table-column>
      <el-table-column
        prop="weightMethod"
        label="评价方式">
      </el-table-column>
      <el-table-column
        width="110"
        align="center"
        label="发布时间">
        <span slot-scope="scope">{{ scope.row.publishTime | parseTimeStr('{y}-{m}-{d}') }}</span>
      </el-table-column>
      <el-table-column
        prop="opt"
        width="80"
        align="center"
        label="操作">
        <el-button slot-scope="scope" @click="toDetail(scope.row)" type="text" size="medium">详情</el-button>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      :page-size="query.limit"
      :current-page="query.page"
      :total="total"
      layout="total,prev, pager, next, jumper"
      background
      prev-text="上一页"
      next-text="下一页"
      @current-change="handlePagination"
    ></el-pagination> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: {
        title:'',
        year:'',
        semester:'',
        // page:1,
        // limit:10,
      },
      yearOpt:[],
      semesterOpt:[
        {
          id:1,
          name:'第一学期'
        },
        {
          id:2,
          name:'第二学期'
        }
      ],
      total:0,
      tableData:[],
    }
  },
  mounted(){
    this.getYear();
    this.getTableList();
  },
  methods: {
    backHandle(){
      this.$router.go(-1)
    },
    getYear(){
      this.$get('evas/paper/student/statics/findYear').then(res=>{
        console.log(res,'====res year')
        if(res.success){
          this.yearOpt = res.result;
        }
      })
    },
    getTableList(){
      this.$get('evas/paper/student/statics/personalEvasStaticsList',this.query).then(res=>{
        if(res.success){
          this.tableData = res.result
        }
      })
    },
    searchHandle(){
      this.getTableList()
    },
    toDetail(row){
      this.$router.push({
        name:'studentEvaluateTotalDetail',
        query:{
          id:row.paperId,
          paperName:row.paperName,
          classAvgScore:row.classAvgScore,
          fullScore:row.fullScore
        }
      })
    },
    handlePagination(e){
      console.log(e,'分页')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 800px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.el-pagination {
  text-align: right;
  margin: 20px 0;
}
</style>
